import useLoadingProgress from "Hooks/useLoadingProgress"
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {selectActiveGroup} from "Store/selectors"

import {useLoadingScreen} from "Components/LoadingScreen"

import {userActions} from "Store/usersSlice"
import {groupActions} from "Store/groupsSlice"
import {installersActions} from "Store/installersSlice"

export default function Initialization() {

	const dispatch = useAppDispatch();
	const progress = useLoadingProgress()
	const loadingScreen = useLoadingScreen()
	const activeGroup = useAppSelector(selectActiveGroup)
	const dashboardFilterPeriod = useAppSelector(state => state.dashboardFilters.period)

	// Download "static" stuff on application startup
	useEffect(() => {
		dispatch(installersActions.list());
		dispatch(groupActions.list());
		dispatch(groupActions.fetchTrainingNames());
		dispatch(groupActions.fetchDownloadReports({}));
	}, [])

	// Download country related stuff after "active country" change
	useEffect(() => {
		if (!activeGroup) return

		progress.start()
		const groupId = activeGroup.id

		Promise.all<any>([
			dispatch(userActions.getMe()),
			dispatch(userActions.list({groupId})),
			dispatch(groupActions.listHospitals({groupId})),
			dispatch(groupActions.fetchReports({
				groupId: activeGroup.id,
				fromDate: dashboardFilterPeriod.begin,
				toDate: dashboardFilterPeriod.end
			}))
		])
			.finally(() => {
				progress.stop()
				loadingScreen.toggleActive(false)
			})
	}, [activeGroup])
	
	// Download dashboard reports after filter period change
	useEffect(() => {
		if (!activeGroup) return
		
		progress.start()
		
		Promise.all<any>([
			dispatch(groupActions.fetchReports({
				groupId: activeGroup.id,
				fromDate: dashboardFilterPeriod.begin,
				toDate: dashboardFilterPeriod.end
			})),

			dispatch(groupActions.fetchDownloadReports({
				fromDate: dashboardFilterPeriod.begin,
				toDate: dashboardFilterPeriod.end
			}))	
		])
			.finally(() => progress.stop())
		
	}, [dashboardFilterPeriod])

	return null;
}